import React, { Component } from "react"
import * as css from "./CommonDoc.module.scss"

class CommonDoc extends Component {
  constructor(props) {
    super()

    this._children = props.children
  }

  render() {
    return (
      <div className={css.base}>
        <div>{this._children}</div>
      </div>
    )
  }
}

export default CommonDoc
