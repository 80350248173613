import React from "react"
import Layout from "../../layouts"
import CommonDoc from "../../components/CommonDoc"
import { PageProps } from "../../commonTypes"

const IndexPage: React.VFC<PageProps> = props => (
  <Layout
    lang="en"
    location={props.location}
    title={`About VIRTUAL ART BOOK FAIR`}
  >
    <CommonDoc>
      <section className={`container`}>
        <div className={`content`}>
          <div className={`content__head`}>
            <h1 className={`haeding__lev1`}>About VIRTUAL ART BOOK FAIR</h1>
          </div>
          <div className={`editor__content`}>
            <p>
              2020 has been a year of change for the world. Even as COVID-19
              rages on, the role of the TOKYO ART BOOK FAIR (TABF), as a
              platform in the art publishing scene, is that of utilizing the
              experiences and connections built up until now, and creating a
              space where people can discover new artbooks and meet like-minded
              people in the new virtual realm. In this age of experimentation,
              we promise not to lose sight of the DIY spirit or our sense of
              humor as we invite you to join us at the VIRTUAL ART BOOK FAIR
              (VABF), an online event featuring an array of experiences to give
              you a multi-faceted look at art books and their many charms.
            </p>
            <p>
              Approximately 230 different exhibitors will prepare booths for
              perusal at VABF, which will take place in a virtual space inspired
              by the Museum of Contemporary Art Tokyo. We will connect with
              visitors digitally: offering exhibitor booths, lectures, talk
              shows, signing events, and more features that capture the allure
              of all types of printed media. We will also be returning with the
              Guest Country series, directing our focus this year on the
              Netherlands. As part of the program, we plan on unraveling Dutch
              publishing culture through various exhibits – one among them
              centering around the talented recipients of the country’s 2019
              Best Dutch Book Design Award. Another is the Dutch Artists’ Books:
              Then and Now exhibit, revolving around interviews with
              frontrunning designers and artists such as Irma Boom, Karel
              Martens, Ruth van Beek, and more: featuring printed media by the
              above artists/designers as well as the books that inspired them.
            </p>
            <p>
              While we intend on keeping the elements that have defined the
              TOKYO ART BOOK FAIR as a physical event in years past, we also
              consider this to be an era of experimentation. We promise not to
              lose sight of our DIY spirit or our sense of humor as we test out
              new possibilities, and we prepared a variety of exciting events,
              exhibitions, and features to give you a multi-layered,
              multi-faceted experience of the art books.
            </p>
            <section>
              <h2>CONTACT</h2>
              <p>
                TOKYO ART BOOK FAIR
                <br />
                <a href="mailto:info@tokyoartbookfair.com">
                  info@tokyoartbookfair.com
                </a>
              </p>
            </section>
          </div>
          {/* /.editor__content */}
        </div>
        {/* /.content */}
      </section>
      {/* /.container */}
    </CommonDoc>
  </Layout>
)

export default IndexPage
